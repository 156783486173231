import React, { useState, useEffect, useRef } from "react";
import { Menu } from "primereact/menu";
import { Button } from "primereact/button";

import { ProductService } from "../../../service/ProductService";

import HeadCards from "./HeadCards";
import RecentJoined from "./RecentJoined";
import ChartsOverview from "./ChartsOverview";
import Notifications from "./Notifications";
import ChartsOverviewSubs from "./ChartsOverviewSubs";

const Dashboard = () => {
  return (
    <div className="grid">
      <HeadCards />

      <div className="col-12 xl:col-6">
        <RecentJoined />
        <Notifications />
      </div>

      <div className="col-12 xl:col-6">
        <ChartsOverview />
        <ChartsOverviewSubs />
      </div>
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return (
    prevProps.location.pathname === nextProps.location.pathname &&
    prevProps.colorMode === nextProps.colorMode
  );
};

export default React.memo(Dashboard, comparisonFn);
