export const subscriptions = [
  {
    name: "walid Menssi",
    email: "walidmenssi@gmail.com",
    phone: "+21652963874",
    type: "Premium",
    date: "2024-10-05",
    price: 69,
    months: 1,
  },
  {
    name: "Mayssa Ben Mokhtar",
    email: "mayssabenmokhtar@gmail.com",
    phone: "+21624598745",
    type: "Pro",
    date: "2024-10-03",
    months: 1,
    price: 35,
  },
  {
    name: "Ahmed Ben Abdalah",
    email: "ahmedbenabdalah@gmail.com",
    phone: "+21652987526",
    type: "Free",
    date: "2024-10-02",
    months: 1,
    price: 0,
  },
  {
    name: "Elyes Kacem",
    email: "elyeskacem@gmail.com",
    phone: "+21658596721",
    type: "Free",
    date: "2024-10-01",
    months: 1,
    price: 0,
  },

  {
    name: "Eya Ben Ismail",
    email: "eyabenesmail@gmail.com",
    phone: "+21658269748",
    type: "Pro",
    date: "2024-09-21",
    months: 1,
    price: 35,
  },
  {
    name: "Hedi Ayari",
    email: "hediayari886@gmail.com",
    phone: "+21695688343",
    type: "Free",
    date: "2024-09-15",
    months: 1,
    price: 0,
  },
  {
    name: "Majed Ben Khalifa",
    email: "majedbenkhalifa@gmail.com",
    phone: "+21624569875",
    type: "Premium",
    date: "2024-09-25",
    months: 1,
    price: 69,
  },
  {
    name: "Hamden Nasri",
    email: "hamden01nasri@gmail.com",
    phone: "+21622521289",
    type: "Pro",
    date: "2024-09-25",
    months: 1,
    price: 35,
  },
  {
    name: "Mourad Masmoudi",
    email: "mouradmasmoudi782@gmail.com",
    phone: "+21652968741",
    type: "Pro",
    date: "2024-10-11",
    months: 1,
    price: 35,
  },
  {
    name: "Mariem Moalla",
    email: "mariemmoualla@gmail.com",
    phone: "+21658743259",
    type: "Free",
    date: "2024-10-12",
    months: 1,
    price: 0,
  },
  {
    name: "chaima gannouni",
    email: "gannounichaima.ch@gmail.com",
    phone: "+21658478236",
    type: "Free",
    date: "2024-08-26",
    months: 1,
    price: 0,
  },
  {
    name: "Mourad Oeslati",
    email: "mouradoueslati55@gmail.com",
    phone: "+21658575963",
    type: "Free",
    date: "2024-08-15",
    months: 1,
    price: 0,
  },

  {
    name: "Ghalia Bouarada",
    email: "hello@startup.gov.tn",
    phone: "+21670258858",
    type: "Free",
    date: "2024-08-01",
    months: 1,
    price: 0,
  },
];
