import React from "react";
import { Dialog } from "primereact/dialog";

import useWindowSize from "../../../../hooks/useWindowSize";
import { Divider } from "primereact/divider";
import ImgBack from "../../../../MyComponents/Image/ImgBack";
import NotionEditor from "../../../../MyComponents/NotionEditor/NotionEditor";

function ShowDialog(props) {
  const { open, handleClose, title = "Show Dialog", value } = props;

  const size = useWindowSize();

  const PopupSize = () => {
    switch (size) {
      case "xl":
        return "70%";
      case "lg":
        return "800px";
      case "md":
        return "700px";
      case "sm":
        return "700px";
      case "xs":
        return "98%";
      default:
        return "80%";
    }
  };

  return (
    <Dialog
      visible={open}
      style={{ width: PopupSize() }}
      header={title}
      modal
      className="p-fluid"
      onHide={handleClose}
    >
      <Divider layout="horizontal" style={{ transform: "translateY(-10px)" }} />
      <div>
        <NotionEditor
          name="blogContent"
          value={value.blogContent}
          editable={false}
          isUpdate={true}
        />
      </div>
    </Dialog>
  );
}

export default ShowDialog;
