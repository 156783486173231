import { AutoComplete } from "primereact/autocomplete";
import React from "react";
import { useHistory } from "react-router-dom";

function InputSearchWebsite() {
  const [selectedAutoValue, setSelectedAutoValue] = React.useState(null);
  const [autoFilteredValue, setAutoFilteredValue] = React.useState([]);
  const history = useHistory(); // Use history to navigate

  // List of random place names as an array of objects with links
  const places = [
    { name: "Dashboard Overview", link: "/dashboard/" },
    { name: "Manage Admins", link: "/dashboard/admins" },
    { name: "Sports Categories", link: "/dashboard/sports" },
    { name: "Coach Services", link: "/dashboard/services" },
    { name: "Nutrition Services", link: "/dashboard/nut-services" },
    { name: "Nutritional Approaches", link: "/dashboard/nut-approches" },
    {
      name: "Nutritional Consultation Modes",
      link: "/dashboard/nut-consult-mode",
    },
    { name: "Manage Users", link: "/dashboard/users" },
    { name: "Manage Coaches", link: "/dashboard/coaches" },
    { name: "Manage Nutritionists", link: "/dashboard/nutritionists" },
    { name: "Blog Management", link: "/dashboard/blogs" },
    { name: "Trenapedia Content", link: "/dashboard/blogs" },
    { name: "renapedia Content", link: "/dashboard/blogs" }, // Kept as an alias
    { name: "User Conversations", link: "/dashboard/conversation" },
    { name: "Mailing System", link: "/dashboard/mailing" },
    { name: "Transaction History", link: "/dashboard/transaction" },
    { name: "Billing System", link: "/dashboard/bills" },
    { name: "Analytics & Insights", link: "/dashboard/analytics-insights" }, // Added
    { name: "Partners & Sponsors", link: "/dashboard/partners-sponsors" }, // Added
    { name: "Support & Feedback", link: "/dashboard/support-feedback" }, // Added
  ];

  // Function to filter suggestions based on user input
  const searchPlace = (event) => {
    let query = event.query.toLowerCase();
    let filtered = places.filter((place) =>
      place.name.toLowerCase().includes(query)
    );
    setAutoFilteredValue(filtered);
  };

  // Function to handle selection and navigate to the place's link
  const handleSelect = (e) => {
    const selectedPlace = e.value;
    setSelectedAutoValue(selectedPlace);

    if (selectedPlace && selectedPlace.link) {
      // Navigate to the selected place's link
      history.push(selectedPlace.link);
      setSelectedAutoValue(null);
    }
  };

  return (
    <span className="p-input-icon-right m-auto">
      <i
        className="pi pi-search"
        style={{ marginRight: "5px", fontSize: "1rem", zIndex: 10 }}
      />
      <AutoComplete
        placeholder="Search"
        className="w-500"
        id="dd"
        value={selectedAutoValue}
        onChange={(e) => setSelectedAutoValue(e.value)}
        suggestions={autoFilteredValue}
        field="name" // Use the `name` field for rendering
        completeMethod={searchPlace} // Attach the search function
        onSelect={handleSelect} // Handle selection and navigate
        dropdown={false} // Disable default dropdown icon
      />
    </span>
  );
}

export default InputSearchWebsite;
