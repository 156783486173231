import React, { useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Divider } from "primereact/divider";
import Uploader from "../../../../MyComponents/Buttons/Uploader";

import { useDispatch, useSelector } from "react-redux";
import { CreateValidation } from "./validation";
import useWindowSize from "../../../../hooks/useWindowSize";
import { UpdateBlogAction } from "../../../../redux/blogs.reducer";

import { BlogContent, MultiSportSelect, MultiTags } from "./AddDialgog";
import NotionEditor from "../../../../MyComponents/NotionEditor/NotionEditor";

const init_create = {
  title: "", //
  description: "", //
  image: "", //
  blogContent: "",
  // tags: [], //
  // sports: [], //
  // VideoLink: "",
};

function UpdateDialgog(props) {
  const { open, handleClose, title = "Update Popup", value } = props;
  const payload = useSelector((state) => state.BlogReducer.payload);

  const [item, setItem] = useState({ ...init_create });
  const dispatch = useDispatch();
  const size = useWindowSize();

  useEffect(() => {
    setItem({ ...value });
  }, [value]);

  const PopupSize = () => {
    switch (size) {
      case "xl":
        return "70%";
      case "lg":
        return "800px";
      case "md":
        return "700px";
      case "sm":
        return "700px";
      case "xs":
        return "98%";
      default:
        return "80%";
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setItem({ ...item, [name]: value });
  };

  const handleSubmit = () => {
    if (CreateValidation(item)) {
      dispatch(UpdateBlogAction(item, handleClose));
    }
  };

  const DialogFooter = (
    <>
      <Button
        label="Cancel"
        icon="pi pi-times"
        className="p-button-outlined"
        onClick={handleClose}
        disabled={payload}
      />
      <Button
        label="Save"
        icon="pi pi-check"
        className=""
        onClick={handleSubmit}
        disabled={payload}
      />
    </>
  );

  return (
    <Dialog
      visible={open}
      style={{ width: PopupSize(), maxHeight: "90vh" }}
      header={title}
      modal
      className="p-fluid"
      footer={DialogFooter}
      onHide={handleClose}
    >
      <div className="grid w-100 mt-2">
        <div className="field col-6 md:col-6">
          <label>Blog Title*</label>
          <InputText
            name="title"
            value={item.title}
            onChange={handleChange}
            required
            autoFocus
          />
        </div>
        <div className="field col-6 md:col-6">
          <label>Upload Blog Icon*</label>
          <Uploader
            label="Upload Blog Main Image"
            name="image"
            value={item.image}
            onChange={handleChange}
            required
          />
        </div>
        <div className="field col-12 md:col-12">
          <label>Blog Description*</label>
          <InputTextarea
            rows={3}
            name="description"
            value={item.description}
            onChange={handleChange}
            required
          />
        </div>

        {/* <div className="field col-12 md:col-12">
          <label>Related Sports</label>
          <MultiSportSelect
            name="sports"
            value={item.sports}
            onChange={handleChange}
          />
        </div> */}
        {/* <div className="field col-12 md:col-12">
          <label>Blog Tags*</label>
          <MultiTags
            name="tags"
            value={item.tags}
            onChange={handleChange}
            required
          />
        </div> */}
        <Divider layout="horizontal" />
        <div className="field col-12 md:col-12">
          <label>Blog Content*</label>
          <NotionEditor
            name="blogContent"
            value={item.blogContent}
            onChange={handleChange}
            editable={true}
            isUpdate={true}
          />
        </div>
      </div>
    </Dialog>
  );
}

export default UpdateDialgog;
