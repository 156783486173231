export const feedbacksDummy = [
  {
    id: 1,
    User: {
      firstName: "Walid",
      lastName: "Menssi",
      email: "walidmenssi@gmail.com",
    },
    comment:
      "The Trenapedia section is fantastic! It helped me learn a lot about proper nutrition and fitness.",
    date: "2024-10-10",
    country: "Tunisia",
    verified: true,
    suspended: false,
  },
  {
    id: 2,
    User: {
      firstName: "Mayssa",
      lastName: "Ben Mokhtar",
      email: "mayssabenmokhtar@gmail.com",
    },
    comment:
      "The Pro plan is worth every penny! The quality of the nutritionists is outstanding.",
    date: "2024-10-07",
    country: "Tunisia",
    verified: true,
    suspended: false,
  },
  {
    id: 3,
    User: {
      firstName: "Ahmed",
      lastName: "Ben Abdalah",
      email: "ahmedbenabdalah@gmail.com",
    },
    comment:
      "I enjoy the Free plan, but some features like personalized coaching should be included.",
    date: "2024-10-06",
    country: "Tunisia",
    verified: true,
    suspended: false,
  },
  {
    id: 4,
    User: {
      firstName: "Elyes",
      lastName: "Kacem",
      email: "elyeskacem@gmail.com",
    },
    comment:
      "Great coaches, but the Premium plan is a bit expensive for me right now.",
    date: "2024-10-04",
    country: "Tunisia",
    verified: true,
    suspended: false,
  },
  {
    id: 5,
    User: {
      firstName: "Eya",
      lastName: "Ben Ismail",
      email: "eyabenesmail@gmail.com",
    },
    comment:
      "The nutrition plans are super detailed and easy to follow. Trenapedia articles are agreat!",
    date: "2024-09-30",
    country: "Tunisia",
    verified: true,
    suspended: false,
  },
];
