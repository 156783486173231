import React, { useEffect } from "react";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";

import default_img from "../../../assets/images/default.jpg";
import { useDispatch, useSelector } from "react-redux";
import { GetUsersAction } from "../../../redux/users.reducer";

function RecentJoined() {
  const dispatch = useDispatch();

  const users = useSelector((state) => state.UsersReducer.users);

  useEffect(() => {
    dispatch(GetUsersAction());
  }, []);

  return (
    <>
      <div className="card">
        <h5>Recent Joined Users</h5>
        <DataTable
          value={users.reverse().slice(0, 15)}
          rows={5}
          paginator
          responsiveLayout="scroll"
        >
          <Column field="fullname" header="Full Name" sortable />
          <Column field="email" header="Email" sortable />
          <Column field="phoneNumber" header="Phone Number" sortable />
        </DataTable>
      </div>
    </>
  );
}

export default RecentJoined;
