import React from "react";
import { Chart } from "primereact/chart";
import { subscriptions } from "../../../assets/data/subs";

const subscriptionCounts = subscriptions.reduce(
  (acc, sub) => {
    acc[sub.type] = (acc[sub.type] || 0) + 1;
    return acc;
  },
  { Free: 0, Pro: 0, Premium: 0 }
);

// Main component
function ChartsOverviewSubs() {
  const data = {
    labels: ["Free", "Pro", "Premium"],
    datasets: [
      {
        label: "Subscriptions",
        data: [
          subscriptionCounts["Free"],
          subscriptionCounts["Pro"],
          subscriptionCounts["Premium"],
        ],
        backgroundColor: ["#000101", "#E60015", "#DAAB1D"],
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: true,
      },
    },
    responsive: true,
  };

  return (
    <div className="card">
      <h5>Subscriptions Overview</h5>
      <Chart type="bar" data={data} options={options} />
    </div>
  );
}

export default ChartsOverviewSubs;
