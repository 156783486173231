import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { partners } from "./data";

import { useSelector, useDispatch } from "react-redux";

import Pagination from "../../../MyComponents/Pagination/Pagination";

import DeleteDialog from "./popups/DeleteDialog";

import FilterComp from "./Filter";

import usePopup from "../../../hooks/usePopup";
import { GetUsersAction } from "../../../redux/users.reducer";
import AvatarComponent from "../../../MyComponents/DataDisplay/Avatar";
import { subscriptions } from "../../../assets/data/subs";

const init_filter = {
  // email:"",
  pays: "",
  suspended: "",
  verified: "",
};

const ActionBodyTemplate = ({ row }) => {
  const [openD, handleOpenD, handleCloseD] = usePopup(row);

  return (
    <div
      className="actions"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
      }}
    >
      {/* ################## DELETE ################## */}

      <Button
        icon="pi pi-pencil"
        tooltip={`Update General Info`}
        tooltipOptions={{ position: "bottom" }}
        className="p-button-rounded p-button-text p-button-info mr-0"
        // onClick={() => handleOpenU()}
      />

      <Button
        icon="pi pi-trash"
        tooltip={`Delete User ${row.title}`}
        tooltipOptions={{ position: "bottom" }}
        className="p-button-rounded p-button-text p-button-danger mr-0"
        onClick={() => handleOpenD()}
      />

      {openD && (
        <DeleteDialog
          open={openD}
          handleClose={handleCloseD}
          value={row}
          title={`Delete User ${row.title}`}
        />
      )}
    </div>
  );
};

const GestFinancials = () => {
  const users = useSelector((state) => state.UsersReducer.users);
  const dispatch = useDispatch();

  //-------------------------------- Pagin & Filter --------------------------------------------
  const [page, setPage] = useState({ p: 0, l: 10 });
  const max = 10;
  const [Filter, setFilter] = useState({ ...init_filter });
  //-------------------------------- Dialog States --------------------------------------------

  //-------------------------------- Get Data --------------------------------------------
  const reloadData = () => {
    dispatch(GetUsersAction(Filter));
  };

  useEffect(() => {
    reloadData();
  }, [Filter]);

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <h5 className="m-0 mr-2">Manage Subscriptions</h5>
      </React.Fragment>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <Button
            icon="pi pi-refresh"
            tooltip="Reload Data"
            className="p-button-primary mr-2"
            onClick={reloadData}
          />
        </div>
      </React.Fragment>
    );
  };

  //------------------------------------------- COLUMNS VALUES ----------------------------------------------

  const AvatarNut = (row) => {
    return (
      <div className=" flex ">
        <AvatarComponent src={null} name={row.name[0]} lastname={row.name[1]} />
      </div>
    );
  };

  const Col2 = (row) => {
    return `${row?.name}`;
  };

  const Col3 = (row) => {
    return row?.email;
  };

  const Col4 = (row) => {
    return row?.phone;
  };

  const Col5 = (row) => {
    return row?.type;
  };

  const Col6 = (row) => {
    return row?.months;
  };

  const Col7 = (row) => {
    return row?.price + " TND";
  };

  const Actions = (row) => {
    return <ActionBodyTemplate row={row} />;
  };

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toolbar
            className="mb-4"
            left={leftToolbarTemplate}
            right={rightToolbarTemplate}
          />

          <DataTable
            value={[...subscriptions]}
            dataKey="_id"
            className="datatable-responsive"
            emptyMessage="No Subscription found."
            header={
              <FilterComp
                filter_name="Filter Subscriptions"
                init_filter={init_filter}
                setFilter={setFilter}
              />
            }
            responsiveLayout="scroll"
          >
            {/* <Column header="Users" body={AvatarNut} /> */}
            <Column field="Full Name" header="Full Name" body={Col2} />
            <Column field="Email" header="Email" body={Col3} />
            <Column field="Phone Number" header="Phone Number" body={Col4} />
            <Column
              field="Subscription type"
              header="Subscription type"
              body={Col5}
            />
            <Column
              field="Number of months"
              header="Number of months"
              body={Col6}
            />
            <Column field="Price" header="Price" body={Col7} />
            <Column header="Actions" body={Actions} />
          </DataTable>
          <Pagination max={max} onPageChange={setPage} />
        </div>
      </div>
    </div>
  );
};

export default GestFinancials;
