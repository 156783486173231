export const partners = [
  {
    _id: "1",
    name: "DECATHLON",
    img: "/images/partners/decat.png",
    joinDate: "2024-07-12",
  },
  {
    _id: "2",
    name: "GSM",
    img: "/images/partners/gsm.png",
    joinDate: "2024-05-20",
  },
  {
    _id: "3",
    name: "IMPACT",
    img: "/images/partners/impact.png",
    joinDate: "2024-08-16",
  },
  {
    _id: "4",
    name: "LESMILLS",
    img: "/images/partners/lesmis.png",
    joinDate: "2024-07-20",
  },
  {
    _id: "5",
    name: "SEM4GYM",
    img: "/images/partners/gsm.png",
    joinDate: "2024-08-10",
  },
  {
    _id: "6",
    name: "CALIFORNIA GYM",
    img: "/images/partners/california.png",
    joinDate: "2024-05-15",
  },
];
