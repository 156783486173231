import React from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { Disconnect } from "../redux/user.reducer";
import logo from "../assets/svgs/logo_corp.svg";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import InputSearchWebsite from "../common/InputSearchWebsite/InputSearchWebsite";

export const AppTopbar = (props) => {
  const dispatch = useDispatch();
  const disconnect = () => {
    dispatch(Disconnect());
  };

  return (
    <div className="layout-topbar">
      <Link to="/" className="layout-topbar-logo">
        <img src={logo} alt="logo" />
        <span>TRENA Admin Hub</span>
      </Link>

      <button
        type="button"
        className="p-link  layout-menu-button layout-topbar-button"
        onClick={props.onToggleMenuClick}
      >
        <i className="pi pi-bars" />
      </button>

      <InputSearchWebsite />

      <button
        type="button"
        className="p-link layout-topbar-menu-button layout-topbar-button"
        onClick={props.onMobileTopbarMenuClick}
      >
        <i className="pi pi-ellipsis-v" />
      </button>

      <ul
        className={classNames("layout-topbar-menu lg:flex origin-top", {
          "layout-topbar-menu-mobile-active": props.mobileTopbarMenuActive,
        })}
      >
        <li>
          <Link
            to="/dashboard"
            className="p-link layout-topbar-button"
            // onClick={props.onMobileSubTopbarMenuClick}
          >
            <i className="pi pi-home" />
            <span>Home</span>
          </Link>

          <Link
            to="/"
            className="p-link layout-topbar-button"
            onClick={props.onMobileSubTopbarMenuClick}
          >
            <i className="pi pi-bell" />
            <span>Notifications</span>
          </Link>
        </li>
        <li>
          <Link
            to="/"
            className="p-link layout-topbar-button"
            onClick={props.onMobileSubTopbarMenuClick}
          >
            <i className="pi pi-cog" />
            <span>Settings</span>
          </Link>
        </li>
        <li>
          <Link
            to="/"
            className="p-link layout-topbar-button"
            onClick={props.onMobileSubTopbarMenuClick}
          >
            <i className="pi pi-user" />
            <span>Profile</span>
          </Link>
        </li>
        <li>
          <Link
            to="/"
            className="p-link layout-topbar-button"
            onClick={disconnect}
          >
            <i className="pi pi-sign-out" />
            <span>Logout</span>
          </Link>
        </li>
      </ul>
    </div>
  );
};
