import React, { useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Divider } from "primereact/divider";
import { MultiSelect } from "primereact/multiselect";
import Uploader from "../../../../MyComponents/Buttons/Uploader";
import { Dropdown } from "primereact/dropdown";

import { useDispatch, useSelector } from "react-redux";
import { CreateValidation } from "./validation";
import useWindowSize from "../../../../hooks/useWindowSize";
import { AddBlogAction } from "../../../../redux/blogs.reducer";
import useSports from "../../../../hooks/useData/useSports";
import ImgBack from "../../../../MyComponents/Image/ImgBack";
import NotionEditor from "../../../../MyComponents/NotionEditor/NotionEditor";

const init_create = {
  title: "", //
  description: "", //
  image: "", //
  blogContent: "",
  // tags: [], //
  // sports: [], //
  // VideoLink: "",
};

function AddDialgog(props) {
  const { open, handleClose, title = "Add Popup" } = props;
  const payload = useSelector((state) => state.BlogReducer.payload);

  const [item, setItem] = useState({ ...init_create });
  const dispatch = useDispatch();
  const size = useWindowSize();

  useEffect(() => {
    console.log("item", item);
  }, [item]);

  const PopupSize = () => {
    switch (size) {
      case "xl":
        return "70%";
      case "lg":
        return "800px";
      case "md":
        return "700px";
      case "sm":
        return "700px";
      case "xs":
        return "98%";
      default:
        return "80%";
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setItem({ ...item, [name]: value });
  };

  const handleSubmit = () => {
    if (CreateValidation(item)) {
      dispatch(AddBlogAction(item, handleClose));
    }
  };

  const DialogFooter = (
    <>
      <Button
        label="Cancel"
        icon="pi pi-times"
        className="p-button-outlined"
        onClick={handleClose}
        disabled={payload}
      />
      <Button
        label="Save"
        icon="pi pi-check"
        className=""
        onClick={handleSubmit}
        disabled={payload}
      />
    </>
  );

  return (
    <Dialog
      visible={open}
      style={{ width: PopupSize(), maxHeight: "90vh" }}
      header={title}
      modal
      className="p-fluid"
      footer={DialogFooter}
      onHide={handleClose}
    >
      <div className="grid w-100 mt-2">
        <div className="field col-6 md:col-6">
          <label>Blog Title*</label>
          <InputText
            name="title"
            value={item.title}
            onChange={handleChange}
            required
            autoFocus
          />
        </div>
        <div className="field col-6 md:col-6">
          <label>Upload Blog Icon*</label>
          <Uploader
            label="Upload Blog Main Image"
            name="image"
            value={item.image}
            onChange={handleChange}
            required
          />
        </div>
        <div className="field col-12 md:col-12">
          <label>Blog Description*</label>
          <InputTextarea
            rows={3}
            name="description"
            value={item.description}
            onChange={handleChange}
            required
          />
        </div>

        {/* <div className="field col-12 md:col-12">
          <label>Related Sports</label>
          <MultiSportSelect
            name="sports"
            value={item.sports}
            onChange={handleChange}
          />
        </div> */}
        {/* <div className="field col-12 md:col-12">
          <label>Blog Tags*</label>
          <MultiTags
            name="tags"
            value={item.tags}
            onChange={handleChange}
            required
          />
        </div> */}
        <Divider layout="horizontal" />
        <div className="field col-12 md:col-12">
          <label>Blog Content*</label>
          <NotionEditor
            name="blogContent"
            value={item.blogContent}
            onChange={handleChange}
            editable={true}
          />
        </div>
      </div>
    </Dialog>
  );
}

export const MultiSportSelect = ({
  onChange = () => {},
  name = "",
  value = [],
}) => {
  const [multiselectValue, setMultiselectValue] = useState([]);
  const sports = useSports();

  useEffect(() => {
    onChange({ target: { name, value: multiselectValue } });
  }, [multiselectValue]);

  useEffect(() => {
    setMultiselectValue(value);
  }, [value]);

  const itemTemplate = (option) => {
    if (option) {
      return (
        <span className=" flex ">
          <ImgBack
            style={{ width: "20px", margin: "0px 10px 0px 5px" }}
            src={option.icon}
          />
          {option.title}
        </span>
      );
    }
    return "Select Sports";
  };

  const SelectedItemTemplate = (option) => {
    if (option) {
      return (
        <span
          style={{
            backgroundColor: "#262438",
            color: "white",
            padding: "2px 6px",
            margin: "0px 5px 0px 0px",
            borderRadius: "5px",
          }}
        >
          {option.title}
        </span>
      );
    }
    return "Select Sports";
  };

  return (
    <MultiSelect
      placeholder="Select Sports"
      optionLabel="email"
      className="w-100"
      maxSelectedLabels={5}
      style={{ width: "100%" }}
      value={multiselectValue}
      options={sports}
      onChange={(e) => setMultiselectValue(e.value)}
      itemTemplate={itemTemplate}
      selectedItemTemplate={SelectedItemTemplate}
      filter
    />
  );
};

export const MultiTags = ({ onChange = () => {}, name = "", value = [] }) => {
  const [tags, setTags] = useState(["", "", ""]);

  useEffect(() => {
    setTags(value);
  }, [value]);

  const AddTag = () => {
    setTags([...tags, ""]);
  };

  const handleChange = (event, index) => {
    const { value } = event.target;
    tags[index] = value;
    setTags([...tags]);
    onChange({ target: { name, value: tags } });
  };

  const RemoveTag = (index) => {
    tags.splice(index, 1);
    setTags([...tags]);
    onChange({ target: { name, value: tags } });
  };

  return (
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      {tags.map((tag, index) => {
        return (
          <span
            className="p-input-icon-right"
            style={{ width: "24%", margin: "5px 0.5% 5px 0.5%" }}
          >
            <i
              className="pi pi-times"
              style={{ cursor: "pointer" }}
              onClick={RemoveTag}
            />
            <InputText
              key={index}
              name="title"
              value={tag}
              placeholder={`Tag ${index + 1}`}
              onChange={(e) => handleChange(e, index)}
            />
          </span>
        );
      })}
      <Button
        label="Add Tag"
        icon="pi pi-plus"
        className="p-button-filled"
        style={{ width: "24%", margin: "5px 0.5% 5px 0.5%" }}
        onClick={AddTag}
      />
    </div>
  );
};

export const BlogContent = ({ value = [], onChange = () => {}, name = "" }) => {
  const options = ["Title", "Paragraph", "Image"];
  const [dropdownValue, setDropdownValue] = useState(options[0]);

  const [content, setContent] = useState(value);

  useEffect(() => {
    onChange({ target: { name, value: content } });
  }, [content]);

  useEffect(() => {
    setContent(value);
  }, [value]);

  const AddContent = () => {
    setContent([...content, { type: dropdownValue, value: "" }]);
  };

  const RemoveContent = (index) => {
    content.splice(index, 1);
    setContent([...content]);
  };

  const handleChanges = (event, index) => {
    content[index].value = event.target.value;
    setContent([...content]);
  };

  const Inputs = [
    { type: "Title", Compo: InputText, placeholder: "Title" },
    { type: "Paragraph", Compo: InputTextarea, placeholder: "Paragraph" },
    { type: "Image", Compo: Uploader, placeholder: "Upload Image" },
  ];

  return (
    <div className="flex" style={{ flexWrap: "wrap" }}>
      {content.map((item, index) => {
        const { Compo, placeholder } = Inputs.find((i) => i.type === item.type);
        return (
          <div
            key={index}
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-bentween",
            }}
          >
            <div
              style={{
                width: "90%",
                margin: "5px 0px 5px 0px",
                padding: "0px 0px 0px 0px",
              }}
            >
              <Compo
                name="content"
                placeholder={placeholder}
                label={placeholder}
                value={item.value}
                onChange={(e) => handleChanges(e, index)}
              />
            </div>
            <Button
              label=""
              icon="pi pi-times"
              className="p-button-filled"
              style={{
                width: "8%",
                margin: "5px 0px 5px 2%",
                height: "60px",
              }}
              onClick={() => RemoveContent(index)}
            />
          </div>
        );
      })}

      <Dropdown
        value={dropdownValue}
        onChange={(e) => setDropdownValue(e.value)}
        options={options}
        placeholder="Select"
        style={{ width: "75%" }}
      />
      <Button
        label="Add Content"
        icon="pi pi-plus"
        className="p-button-filled"
        style={{ width: "24%", margin: "0px 0px 0px 1%" }}
        onClick={AddContent}
      />
    </div>
  );
};

export default AddDialgog;
